// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// LIBRARIES
import { omit } from 'lodash'

// STORE
import store, { GET_DEFAULT_PAGINATOR } from '@/store'

// INTERFACES
import {
  CountResource,
  DictionaryPenaltyResource,
  NameValueResource,
  PenaltyCarParkIdGetParams,
  PaginationMeta,
  PenaltySum,
  IPenaltySumToPay,
  PenaltyPaginator,
  // PenaltyStatusEnum,
} from '../../types'

// SCHEMA METHODS
import {
  DictionariesPenaltyDriverCarIdGetRequest,
  PenaltyDriverCarIdGetRequest,
  PenaltyDriverPenaltyIdPutRequest,
  PenaltyDriverCountCarIdGetRequest,
  PenaltyDriverSumCarIdGetRequest,
} from '../../types/schema'

// HELPERS
import { deleteEmpty } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'driver-penalty',
  store,
  namespaced: true,
})
class DriverPenalty extends VuexModule {
  // 0======================================== PENALTIES ========================================+o>
  @Mutation
  setPaginationMeta (payload: PaginationMeta) {
    Vue.set(this, 'paginator', payload)
  }

  penalties: PenaltyPaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setPenalties (payload: PenaltyPaginator) {
    // payload
    // const test = GET_DEFAULT_PAGINATOR()
    // test.data = [
    //   {
    //     id: 123,
    //     carModel: 'string',
    //     vin: 'string',
    //     stateNumber: 'string',
    //     protocolDate: 'string',
    //     protocolNumber: '9067312312312312312312313',
    //     violationPhoto: 'string',
    //     violationDate: 'string',
    //     violationArticle: 'string',
    //     violationArticleDescription: 'string',
    //     violationPlace: 'string',
    //     dateOfGettingOriginal: 'string',
    //     cost: 500,
    //     discountCost: 250,
    //     discountEndDate: 'string',
    //     authority: 'string',
    //     paymentLink: 'string',
    //     fileLink: '',
    //     status: PenaltyStatusEnum.NEW,
    //     paid: false,
    //     challenging: false,
    //   },
    //   {
    //     id: 1223,
    //     carModel: 'string',
    //     vin: 'string',
    //     stateNumber: 'string',
    //     protocolDate: 'string',
    //     protocolNumber: '90673123123',
    //     violationPhoto: 'string',
    //     violationDate: 'string',
    //     violationArticle: 'string',
    //     violationArticleDescription: 'string',
    //     violationPlace: 'string',
    //     dateOfGettingOriginal: 'string',
    //     cost: 500,
    //     discountCost: 250,
    //     discountEndDate: 'string',
    //     authority: 'string',
    //     paymentLink: 'string',
    //     fileLink: '',
    //     status: PenaltyStatusEnum.NEW,
    //     paid: false,
    //     challenging: false,
    //   },
    //   {
    //     id: 12223,
    //     carModel: 'string',
    //     vin: 'string',
    //     stateNumber: 'string',
    //     protocolDate: 'string',
    //     protocolNumber: '9067312',
    //     violationPhoto: 'string',
    //     violationDate: 'string',
    //     violationArticle: 'string',
    //     violationArticleDescription: 'string',
    //     violationPlace: 'string',
    //     dateOfGettingOriginal: 'string',
    //     cost: 500,
    //     discountCost: 250,
    //     discountEndDate: 'string',
    //     authority: 'string',
    //     paymentLink: 'string',
    //     fileLink: 'link',
    //     status: PenaltyStatusEnum.NEW,
    //     paid: false,
    //     challenging: false,
    //   },
    //   {
    //     id: 117,
    //     carModel: 'Suzuki Модель-quis',
    //     vin: 'TMBAB6NP5H7513491',
    //     stateNumber: 'G972YG69',
    //     protocolDate: '2022-03-17',
    //     protocolNumber: '90673',
    //     violationPhoto: null,
    //     violationDate: '2022-04-24 05:36:05+00',
    //     violationArticle: 'Статья 12.11. Нарушение правил движения по автомагистрали',
    //     violationArticleDescription: 'Est id eligendi velit sunt.',
    //     violationplace: '4405 Martin Mission Apt. 614\nGodfreyhaven, TN 27641',
    //     dateOfGettingOriginal: '2020-05-21',
    //     cost: 5000,
    //     discountCost: 2500,
    //     discountEndDate: '2022-03-20',
    //     authority: 'Officiis.',
    //     paymentLink: null,
    //     fileLink: 'https://intermark.na.atwinta.ru/storage/image/NdoliV5oYcp3BhcirScusi3ZgvcCyWZ1E0b7eQVA.jpg',
    //     status: 'challenging',
    //     challenging: false,
    //     paid: false,
    //   },
    // // ].concat(payload.data as never[]) as never[]
    // ] as never[]
    // Vue.set(this, 'penalties', test)

    Vue.set(this, 'penalties', payload)
  }

  @Action({ rawError: true })
  async getPenalties (payload: number) {
    const { data } = await PenaltyDriverCarIdGetRequest(payload, deleteEmpty(this.penaltyFilter))

    this.setPenalties(data)
  }

  // Отправить на оспаривание
  @Action({ rawError: true })
  async submitForDispute (payload: number) {
    PenaltyDriverPenaltyIdPutRequest(payload)
  }

  // 0========================================== SUMS ===========================================+o>
  penaltiesSum: NameValueResource[] = []
  penaltiesSumToPay: IPenaltySumToPay = {}

  @Mutation
  setPenaltiesSum (payload: PenaltySum) {
    Vue.set(this, 'penaltiesSum', payload.sums)
    Vue.set(this, 'penaltiesSumToPay', {
      cost: payload.cost,
      discountSum: payload.discountSum,
    })
  }

  @Action({ rawError: true })
  async getPenaltiesSum (payload: number) {
    const { data } = await PenaltyDriverSumCarIdGetRequest(payload)

    this.setPenaltiesSum(data)
  }

  // 0======================================= DICTIONARY ========================================+o>
  statusesDictionary: CountResource[] = []

  filtersDictionary: DictionaryPenaltyResource = {
    vin: [],
    carModelId: [],
    protocolNumber: [],
    articleViolation: [],
    eventPlace: [],
    authority: [],
    stateNumber: [],
  }

  @Mutation
  setStatusesDictionary (payload: CountResource[]) {
    Vue.set(this, 'statusesDictionary', payload)
  }

  @Mutation
  setFiltersDictionary (payload: DictionaryPenaltyResource) {
    Vue.set(this, 'filtersDictionary', payload)
  }

  @Action({ rawError: true })
  async getStatusesDictionary (payload: number) {
    const { data } = await PenaltyDriverCountCarIdGetRequest(
      payload,
      deleteEmpty({ ...omit(this.penaltyFilter, 'status') }),
    )

    this.setStatusesDictionary(data)
  }

  @Action({ rawError: true })
  async getFiltersDictionary (payload: number) {
    const { data } = await DictionariesPenaltyDriverCarIdGetRequest(payload)

    this.setFiltersDictionary(data)
  }

  // 0===================================== REQUEST-PARAMS ======================================+o>
  penaltyFilter: PenaltyCarParkIdGetParams = {
    page: 1, // default
    perPage: 5, // default
  }

  @Mutation
  setPerPage (payload: number) {
    Vue.set(this.penaltyFilter, 'perPage', payload)
  }

  @Mutation
  setPage (payload: number) {
    Vue.set(this.penaltyFilter, 'page', payload)
  }

  @Mutation
  setStatus (payload: string | undefined) {
    Vue.set(this.penaltyFilter, 'status', payload)
  }

  @Mutation
  setFilters (payload: PenaltyCarParkIdGetParams) {
    Vue.set(this, 'penaltyFilter', { ...this.penaltyFilter, ...payload })
  }

  @Mutation
  resetFilters () {
    Vue.set(this, 'penaltyFilter', { perPage: this.penaltyFilter.perPage, page: 1 })
  }
}

const DriverPenaltyModule = getModule(DriverPenalty)

export default DriverPenaltyModule
